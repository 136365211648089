import request from '@/plugins/axios'


/** S 微信小程序设置 **/
// 获取微信小程序设置
export const apiWechatMiniSetting = (): Promise<any> =>
    request.get('/settings.wechat/getMpConfig')
// 微信小程序设置
export const apiWechatMiniSettingSet = (data: any): Promise<any> =>
    request.post('/settings.wechat/setMpConfig', data)
/** E 微信小程序设置 **/
